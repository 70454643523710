import { css } from '@emotion/core';

export const rootContainerStyle = css`
  background: #ebeced;
  display: flex;
  min-height: 100vh;
  padding-bottom: 150px;
  flex-direction: column;
  align-items: center;
  @media(max-width: 640px) {
    padding-bottom: 250px;
  }
`;

export const contentContainerStyle = css`
  display: flex;
  padding: 100px 20px 0 20px;
  max-width: 500px;
  flex-direction: column;
  align-items: center;
`;
