// let API_URL = 'http://localhost:3000/kode';
let API_URL = 'https://api.hacktiv8.com/kode';

if (process.env.NODE_ENV === 'production') {
  API_URL = 'https://api.hacktiv8.com/kode';
}

if (process.env.REACT_APP_ENV_NAME === 'staging') {
  API_URL = 'https://kode-payment-api-staging.herokuapp.com/api';
}

export const KODE_PAYMENT_API_URL = API_URL;
