/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useState, useEffect } from 'react';

import {
  rootContainerStyle,
  contentContainerStyle,
  logoStyle,
  voucherInstructionLabelStyle,
  voucherInputStyle,
  redeemVoucherButtonStyle,
  redeemSupportStyle,
  errorMessageStyle,
  redeemSuccessBannerStyle,
  redeemSuccessContentContainerStyle,
  redeemSuccessHeadingStyle,
  redeemSuccessContentStyle,
  startLearningButtonStyle,
  linkNoEffectStyle,
  maintenanceModeContainerStyles,
  maintenanceModeContentStyles,
  maintenanceModeDescriptionContainerStyles,
  maintenanceModeTitleStyles,
  maintenanceModeDescriptionStyles,
  maintenanceModeImageStyles,
} from './KodeRedeem.styles';

import { validateVoucher, redeemVoucher, fetchCourseByThinkificId } from './KodeRedeem.services';

import Logo from '../../assets/images/logo/kode-logo.png';
import MaintenanceMode from '../../assets/images/maintenance/maintenance.png';

import { API_STATUS } from '../../constants/api';

import SessionProviderSvc from '../../services/session';

const KodeRedeem = () => {
  // UI states
  const [user, setUser] = useState(null);
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const [isVoucherCodeFormatValid, setIsVoucherCodeFormatValid] = useState(false);
  const [editableVoucherCode, setEditableVoucherCode] = useState('');
  const [redeemedCourseTitle, setRedeemedCourseTitle] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  // API states
  const [voucherValidationApiState, setVoucherValidationApiState] = useState(API_STATUS.IDLE);
  const [voucherRedemptionApiState, setVoucherRedemptionApiState] = useState(API_STATUS.IDLE);

  const handleRedeemVoucher = async () => {
    try {
      setVoucherRedemptionApiState(API_STATUS.LOADING);
      setIsFormDisabled(true);

      const resp = await redeemVoucher({
        external_id: user.id,
        thinkific_id: 'NOT SPECIFIED',
        first_name: user.firstName,
        last_name: user.lastName,
        email: user.email,
        voucher_code: editableVoucherCode.split('-').join('').split(' ').join(''),
      });

      // Fetch both course and bundle to get either one of them
      // there are no way a single id can obtain both course and bundle
      const [course, bundle] = await Promise.all([
        fetchCourseByThinkificId(resp.thinkific_course_external_id),
        fetchCourseByThinkificId(resp.thinkific_bundle_external_id),
      ]);

      const title = course ? course.kode_course_title : bundle.kode_course_title;

      setRedeemedCourseTitle(title);
      setIsFormDisabled(false);
      setVoucherRedemptionApiState(API_STATUS.SUCCESS);
    } catch (e) {
      setIsFormDisabled(false);

      setErrorMessage(e.message || 'Ups, terjadi gangguan pada server. Mohon coba lagi.');
      setVoucherRedemptionApiState(API_STATUS.IDLE);
    }
  };

  const handleVoucherValidation = async (cleanVoucherCode) => {
    try {
      setVoucherValidationApiState(API_STATUS.LOADING);
      setIsFormDisabled(true);
      await validateVoucher(cleanVoucherCode);
      setIsFormDisabled(false);
      setVoucherValidationApiState(API_STATUS.SUCCESS);
      setErrorMessage(null);
      setIsFormDisabled(false);
    } catch (e) {
      setIsFormDisabled(false);
      setErrorMessage(e.message || 'Ups, terjadi gangguan pada server. Mohon coba lagi.');
      setVoucherValidationApiState(API_STATUS.IDLE);
    }
  };

  const handleSetVoucherCode = ({ target: { value } }) => {
    const cleanValue = value.split('-').join('').split(' ').join('');
    const regex = new RegExp(/^[a-z0-9]+$/i);
    const isValid = regex.test(cleanValue);
    if ((isValid || cleanValue === '') && cleanValue.length <= 12) {
      // eslint-disable-next-line arrow-body-style
      const genMaskedValue = (str) => {
        return str.split('').map((c, idx) => {
          if (idx > 0 && idx % 4 === 0) { return ` - ${c}`; }
          return c;
        }).join('');
      };

      setEditableVoucherCode(genMaskedValue(cleanValue));
      if (!isVoucherCodeFormatValid && cleanValue.length === 12) {
        setIsVoucherCodeFormatValid(true);
        handleVoucherValidation(cleanValue);
      }

      if (isVoucherCodeFormatValid && cleanValue.length === 12) {
        handleVoucherValidation(cleanValue);
      }

      if (isVoucherCodeFormatValid && cleanValue.length < 12) {
        setIsVoucherCodeFormatValid(false);
      }
    }
  };

  useEffect(() => {
    setUser(SessionProviderSvc.getSessionData());
  }, []);

  const isRedeemButtonDisabled = !isVoucherCodeFormatValid
    && voucherValidationApiState === API_STATUS.IDLE;

  if (process.env.REACT_APP_VOUCHER_REDEEM_PAGE_ENABLED === 'false') {
    return (
      <div css={maintenanceModeContainerStyles}>
        <div css={maintenanceModeContentStyles}>
          <div css={maintenanceModeDescriptionContainerStyles}>
            <h1 css={maintenanceModeTitleStyles}>under maintenance</h1>
            <p css={maintenanceModeDescriptionStyles}>
              Kami sedang melakukan pemeliharaan sistem. Silahkan coba kembali beberapa saat.
            </p>
          </div>
          <img src={MaintenanceMode} css={maintenanceModeImageStyles} alt="maintenance-mode" />
        </div>
      </div>
    );
  }

  if (user && voucherRedemptionApiState !== API_STATUS.SUCCESS) {
    return (
      <div css={rootContainerStyle}>
        <div css={contentContainerStyle}>
          <img src={Logo} css={logoStyle} alt="kode.id" />
          <span css={voucherInstructionLabelStyle}>Silakan Redeem Vouchermu Disini</span>
          <input
            css={voucherInputStyle}
            type="text"
            value={editableVoucherCode}
            onChange={handleSetVoucherCode}
            disabled={isFormDisabled}
            style={{
              letterSpacing: editableVoucherCode === '' ? 0 : '2.8px',
              // eslint-disable-next-line no-nested-ternary
              borderColor: errorMessage
                ? '#e65856'
                : voucherValidationApiState === API_STATUS.SUCCESS ? '#18b7bc' : '#ebeced',
            }}
            placeholder="Masukan 12 digit kode voucher"
          />
          {errorMessage && <div css={errorMessageStyle}>{errorMessage}</div>}
          <div
            css={redeemVoucherButtonStyle}
            onClick={isRedeemButtonDisabled ? () => {} : handleRedeemVoucher}
            onKeyUp={() => {}}
            role="button"
            tabIndex={0}
            style={{
              cursor: isRedeemButtonDisabled ? 'default' : 'pointer',
              background: isRedeemButtonDisabled ? '#9ba2a9' : '#e65856',
            }}
          >
            <span style={{ fontWeight: 700 }}>
              {(
                voucherValidationApiState === API_STATUS.LOADING)
                || (voucherRedemptionApiState === API_STATUS.LOADING)
                ? 'Loading...'
                : 'Redeem Voucher'}
            </span>
          </div>
          <div css={redeemSupportStyle}>
            <span>
              Mengalami kendala saat me-redeem voucher?
              <a href="https://www.kode.id/pages/contact-us" target="_blank" rel="noopener noreferrer">Hubungi Kami</a>
            </span>
          </div>
        </div>
      </div>
    );
  }

  if (user && voucherRedemptionApiState === API_STATUS.SUCCESS) {
    return (
      <div css={rootContainerStyle}>
        <div css={contentContainerStyle} style={{ padding: 0 }}>
          <div css={redeemSuccessBannerStyle} />
          <div css={redeemSuccessContentContainerStyle}>
            <div css={redeemSuccessHeadingStyle}>
              Yeay! Voucher berhasil ditukar!
            </div>
            <div css={redeemSuccessContentStyle}>
              Selamat, course
              <span>{` ${redeemedCourseTitle} `}</span>
              yang kamu ikuti telah berhasil diaktifkan.
            </div>
            <a href="https://www.kode.id/enrollments" css={linkNoEffectStyle}>
              <div
                css={startLearningButtonStyle}
                onClick={() => {}}
                onKeyUp={() => {}}
                role="button"
                tabIndex={0}
              >
                <span>Mulai Belajar</span>
              </div>
            </a>
          </div>
        </div>
      </div>
    );
  }

  return <div>error</div>; // TODO: dedicated error page
};

export default KodeRedeem;
