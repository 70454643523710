/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Global } from "@emotion/core";

import ExternalVendorSvc from "./services/external";
import SessionProviderSvc from "./services/session";

import routeConfig, { notFoundRoute } from "./routes";
import { globalStyles, siteConfig } from "./configs";

import Header from "./components/Header";

// Side effect: redirects site to login when user unauthenticated
const redirectToAuth = () => {
  console.log("redirecting out..");
  console.log(process.env.REACT_APP_REDIRECT_TO_AUTH_URL);
  window.location.replace(process.env.REACT_APP_REDIRECT_TO_AUTH_URL);
};

// Prepares external vendor services and check for authentication
const bootApp = () => {
  ExternalVendorSvc.init();

  if (!SessionProviderSvc.auth().isAuthenticated) {
    redirectToAuth();
  }
};

const App = () => {
  useEffect(() => {
    bootApp();
  }, []);

  return (
    <>
      <Helmet>
        <title>{siteConfig.title}</title>
      </Helmet>
      <Global styles={globalStyles} />
      <Header />
      <Switch>
        {routeConfig.map((route) => (
          <Route
            key={route.path}
            exact={route.isExact}
            path={route.path}
            component={route.component}
          />
        ))}
        <Route component={notFoundRoute.component} />
      </Switch>
      <div id="whatsapp-widget" style={{ display: "block" }}>
        <span>
          <a
            href="https://api.whatsapp.com/send?phone=622180675787&text=Halo,%20saya%20mau%20informasi%20lebih%20lanjut%20tentang%20KODE"
            target="_blank"
          >
            <img
              src="https://s3.ap-southeast-1.amazonaws.com/statics.hacktiv8.com/whatsapp.png"
              width="60"
              height="60"
              style={{
                position: "fixed",
                bottom: "10px",
                right: "10px",
                zIndex: "999",
              }}
            />
          </a>
        </span>
      </div>
    </>
  );
};

export default App;
