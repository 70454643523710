import {
  SinglePurchaseCheckout,
  KodeRedeem,
  ProcessPayment,
  PrakerjaCallback,
  PrakerjaRedeem,
  NotFound,
  VoucherRedeem,
  SubmitPrakerjaSubmission,
  PrakerjaScore,
} from "./pages";

export const notFoundRoute = {
  component: NotFound,
};

export default [
  {
    isExact: true,
    path: "/purchase/:id",
    component: SinglePurchaseCheckout,
  },
  {
    isExact: true,
    path: "/voucher-redeem",
    component: VoucherRedeem,
  },
  {
    isExact: true,
    path: "/voucher-redeem-kode",
    component: KodeRedeem,
  },
  {
    isExact: true,
    path: "/submit-prakerja-submission",
    component: SubmitPrakerjaSubmission,
  },
  {
    isExact: true,
    path: "/voucher-redeem-prakerja",
    component: PrakerjaRedeem,
  },
  {
    isExact: true,
    path: "/prakerja-score",
    component: PrakerjaScore,
  },
  {
    isExact: true,
    path: "/process-payment",
    component: ProcessPayment,
  },
  {
    isExact: true,
    path: "/callback",
    component: PrakerjaCallback,
  },
];
