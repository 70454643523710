import { css } from '@emotion/core';

export const logoStyle = css`
  width: 100px;
`;

export const headerStyle = css`
  width: 100%;
  box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  padding: 17px 20px;
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
`;

export const navStyle = css`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  @media (min-width: 960px) {
    padding: 0 20px;
  }
  align-items: center;
`;
