import axios from 'axios';

import { KODE_PAYMENT_API_URL } from '../../configs/api';

const fetchCoursePriceById = async (id, resolve, reject) => {
  try {
    const { data } = await axios.get(`${KODE_PAYMENT_API_URL}/v1/course-prices/thinkific/${id}`);
    if (!data.data) {
      throw new Error('Course tidak ditemukan');
    }
    resolve(data.data);
  } catch (e) {
    reject(e);
  }
};

const checkPromoCode = (promoCode) => (
  axios.get(`${KODE_PAYMENT_API_URL}/v1/promo-codes/${promoCode}`,
    {
      headers: {
        'x-api-key': 'p4ym3nt_S3rv1ce_API',
      },
    })
);

// eslint-disable-next-line
const createInvoice = (inv) => new Promise(async (resolve, reject) => {
  try {
    const { data } = await axios.post(
      `${KODE_PAYMENT_API_URL}/v1/course-purchases`,
      inv,
    );

    resolve(data.data);
  } catch (e) {
    reject(e);
  }
});

export { fetchCoursePriceById, checkPromoCode, createInvoice };
