import globalStyles from './styles.global';
import colors from './colors';

const siteConfig = {
  title: 'KODE PAY - Semua Bisa Belajar',
};

export {
  colors,
  globalStyles,
  siteConfig,
};
