/* eslint-disable max-len */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useState, useEffect } from 'react';

import {
  rootContainerStyle,
  contentContainerStyle,
  logoStyle,
  voucherInstructionLabelStyle,
  voucherInputStyle,
  redeemVoucherButtonStyle,
  redeemSupportStyle,
  errorMessageStyle,
  redeemSuccessBannerStyle,
  redeemSuccessContentContainerStyle,
  redeemSuccessHeadingStyle,
  // redeemSuccessContentStyle,
  startLearningButtonStyle,
  linkNoEffectStyle,
  maintenanceModeContainerStyles,
  maintenanceModeContentStyles,
  maintenanceModeDescriptionContainerStyles,
  maintenanceModeTitleStyles,
  maintenanceModeDescriptionStyles,
  maintenanceModeImageStyles,
} from './PrakerjaRedeem.styles';

import { redeemVoucher } from './PrakerjaRedeem.services';

import Logo from '../../assets/images/logo/kode-logo.png';
import MaintenanceMode from '../../assets/images/maintenance/maintenance.png';

import { API_STATUS } from '../../constants/api';

import SessionProviderSvc from '../../services/session';

const PrakerjaRedeem = () => {
  // UI states
  const [user, setUser] = useState(null);
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const [isVoucherCodeFormatValid, setIsVoucherCodeFormatValid] = useState(false);
  const [voucherCode, setVoucherCode] = useState('');
  const [redemptionCode, setRedemptionCode] = useState('');
  const [sequence, setSequence] = useState('');
  // const [redeemedCourseTitle, setRedeemedCourseTitle] = useState(null);
  const [redirectionUrl, setRedirectionUrl] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  // API states
  const [voucherValidationApiState, setVoucherValidationApiState] = useState(API_STATUS.IDLE);
  const [voucherRedemptionApiState, setVoucherRedemptionApiState] = useState(API_STATUS.IDLE);
  const [isRedeemButtonDisabled, setRedeemButtonDisabled] = useState(!isVoucherCodeFormatValid
    && voucherValidationApiState === API_STATUS.IDLE);

  const voucherCodeCleaner = (v) => v.split('-').join('').split(' ').join('');
  const voucherCodeLength = 12;
  const redemptionCodeLength = 13;

  const handleRedeemVoucher = async () => {
    try {
      setVoucherRedemptionApiState(API_STATUS.LOADING);
      setIsFormDisabled(true);

      const resp = await redeemVoucher({
        external_id: user.id,
        thinkific_id: 'NOT SPECIFIED',
        first_name: user.firstName,
        last_name: user.lastName,
        email: user.email,
        voucher_code: voucherCodeCleaner(voucherCode).toLowerCase(),
        // redemption_code: voucherCodeCleaner(redemptionCode),
        redemption_code: redemptionCode,
        sequence: Number(sequence),
      });


      // Fetch both course and bundle to get either one of them
      // there are no way a single id can obtain both course and bundle
      // const [{ data: { data: course } }, { data: { data: bundle } }] = await Promise.all([
      //   fetchCourseByThinkificId(resp.thinkific_course_external_id),
      //   fetchCourseByThinkificId(resp.thinkific_bundle_external_id),
      // ]);

      // const title = course ? course.kode_course_title : bundle.kode_course_title;

      // setRedeemedCourseTitle(title);
      setRedirectionUrl(resp.url);
      setIsFormDisabled(false);
      setVoucherRedemptionApiState(API_STATUS.SUCCESS);
    } catch (e) {
      setIsFormDisabled(false);

      setErrorMessage(e.message || 'Ups, terjadi gangguan pada server. Mohon coba lagi.');
      setVoucherRedemptionApiState(API_STATUS.IDLE);
    }
  };

  // const handleVoucherValidation = async (cleanVoucherCode, cleanRedemptionCode) => {
  const handleVoucherValidation = async () => {
    try {
      setVoucherValidationApiState(API_STATUS.LOADING);
      setIsFormDisabled(true);
      // await validateVoucher(cleanVoucherCode, cleanRedemptionCode);
      setIsFormDisabled(false);
      setVoucherValidationApiState(API_STATUS.SUCCESS);
      setErrorMessage(null);
      setIsFormDisabled(false);
      setRedeemButtonDisabled(false);
    } catch (e) {
      setIsFormDisabled(false);
      setErrorMessage(e.message || 'Ups, terjadi gangguan pada server. Mohon coba lagi.');
      setVoucherValidationApiState(API_STATUS.IDLE);
      setRedeemButtonDisabled(true);
    }
  };

  const isVoucherCodeValid = (value) => {
    const cleanValue = voucherCodeCleaner(value);
    const regex = new RegExp(/^[a-z0-9]+$/i);
    const isValid = regex.test(cleanValue);

    return (isValid || cleanValue === '') && cleanValue.length <= voucherCodeLength;
  };

  const isRedemptionCodeValid = (value) => {
    const cleanValue = voucherCodeCleaner(value);
    const regex = new RegExp(/^[A-Z0-9]+$/i);
    const isValid = regex.test(cleanValue);

    return (isValid || cleanValue === '') && cleanValue.length <= redemptionCodeLength;
  };

  const isSequenceValid = () => sequence > 0;

  // const genMaskedValue = (str) => str.split('').map((c, idx) => {
  //   if (idx > 0 && idx % 4 === 0) { return ` - ${c}`; }
  //   return c;
  // }).join('');

  const handleSetVoucherCode = (value) => {
    // const cleanValue = voucherCodeCleaner(value).slice(0, voucherCodeLength).toLowerCase();
    const cleanValue = value.slice(0, voucherCodeLength).toUpperCase();

    if (isVoucherCodeValid(value)) {
      // setVoucherCode(genMaskedValue(cleanValue));
      setVoucherCode(cleanValue);
    }
  };

  const handleSetRedemptionCode = (value) => {
    // const cleanValue = voucherCodeCleaner(value).slice(0, redemptionCodeLength).toUpperCase();
    const cleanValue = value.slice(0, redemptionCodeLength).toUpperCase();

    if (isRedemptionCodeValid(cleanValue)) {
      // setRedemptionCode(genMaskedValue(cleanValue));
      setRedemptionCode(cleanValue);
    }
  };

  useEffect(() => {
    const voucherCleanValue = voucherCodeCleaner(voucherCode);
    const redemptionCleanValue = voucherCodeCleaner(redemptionCode);

    if (
      (!isVoucherCodeValid(voucherCode) && voucherCleanValue.length === voucherCodeLength)
      || (!isRedemptionCodeValid(redemptionCode) && redemptionCleanValue.length === redemptionCodeLength)
      || isSequenceValid()
    ) {
      setIsVoucherCodeFormatValid(true);
      handleVoucherValidation(voucherCleanValue, redemptionCleanValue);
    }

    if (
      isVoucherCodeValid(voucherCode) && voucherCleanValue.length === voucherCodeLength
      && isRedemptionCodeValid(redemptionCode) && redemptionCleanValue.length === redemptionCodeLength
      && isSequenceValid()
    ) {
      handleVoucherValidation(voucherCleanValue, redemptionCleanValue);
    }

    if (
      (isVoucherCodeValid(voucherCode) && voucherCleanValue.length < voucherCodeLength)
      || (isRedemptionCodeValid(redemptionCode) && redemptionCleanValue.length < redemptionCodeLength)
      || !isSequenceValid()
    ) {
      setIsVoucherCodeFormatValid(false);
    }
    // eslint-disable-next-line
  }, [voucherCode, redemptionCode, sequence]);

  useEffect(() => {
    setUser(SessionProviderSvc.getSessionData());
  }, []);

  if (process.env.REACT_APP_VOUCHER_REDEEM_PAGE_ENABLED === 'false') {
    return (
      <div css={maintenanceModeContainerStyles}>
        <div css={maintenanceModeContentStyles}>
          <div css={maintenanceModeDescriptionContainerStyles}>
            <h1 css={maintenanceModeTitleStyles}>under maintenance</h1>
            <p css={maintenanceModeDescriptionStyles}>
              Kami sedang melakukan pemeliharaan sistem. Silahkan coba kembali beberapa saat.
            </p>
          </div>
          <img src={MaintenanceMode} css={maintenanceModeImageStyles} alt="maintenance-mode" />
        </div>
      </div>
    );
  }

  if (user && voucherRedemptionApiState !== API_STATUS.SUCCESS) {
    return (
      <div css={rootContainerStyle}>
        <div css={contentContainerStyle}>
          <img src={Logo} css={logoStyle} alt="kode.id" />
          <span css={voucherInstructionLabelStyle}>Silakan Redeem Vouchermu Disini</span>
          <input
            css={voucherInputStyle}
            type="text"
            value={voucherCode}
            onChange={(e) => handleSetVoucherCode(e.target.value)}
            disabled={isFormDisabled}
            style={{
              letterSpacing: voucherCode === '' ? 0 : '2.8px',
              // eslint-disable-next-line no-nested-ternary
              borderColor: errorMessage
                ? '#e65856'
                : voucherValidationApiState === API_STATUS.SUCCESS ? '#18b7bc' : '#ebeced',
            }}
            placeholder="Masukan 12 digit kode voucher"
          />
          <input
            className="second-input"
            css={voucherInputStyle}
            type="text"
            value={redemptionCode}
            onChange={(e) => handleSetRedemptionCode(e.target.value)}
            disabled={isFormDisabled}
            style={{
              letterSpacing: redemptionCode === '' ? 0 : '1.4px',
              // eslint-disable-next-line no-nested-ternary
              borderColor: errorMessage
                ? '#e65856'
                : voucherValidationApiState === API_STATUS.SUCCESS ? '#18b7bc' : '#ebeced',
            }}
            placeholder="Masukkan 12 digit kode redemption"
          />
          <input
            className="second-input"
            css={voucherInputStyle}
            type="number"
            value={sequence}
            onChange={(e) => setSequence(e.target.value)}
            disabled={isFormDisabled}
            style={{
              letterSpacing: redemptionCode === '' ? 0 : '1.4px',
              // eslint-disable-next-line no-nested-ternary
              borderColor: errorMessage
                ? '#e65856'
                : voucherValidationApiState === API_STATUS.SUCCESS ? '#18b7bc' : '#ebeced',
            }}
            placeholder="Masukkan tahapan kehadiran"
          />
          {errorMessage && <div css={errorMessageStyle}>{errorMessage}</div>}
          <div
            css={redeemVoucherButtonStyle}
            onClick={isRedeemButtonDisabled ? () => { } : handleRedeemVoucher}
            onKeyUp={() => { }}
            role="button"
            tabIndex={0}
            style={{
              cursor: isRedeemButtonDisabled ? 'not-allowed' : 'pointer',
              background: isRedeemButtonDisabled ? '#9ba2a9' : '#e65856',
            }}
          >
            <span style={{ fontWeight: 700 }}>
              {(
                voucherValidationApiState === API_STATUS.LOADING)
                || (voucherRedemptionApiState === API_STATUS.LOADING)
                ? 'Loading...'
                : 'Redeem Voucher'}
            </span>
          </div>
          <div style={{ margin: '25px 0 0' }}>

            <p style={{ fontSize: 12, color: '#394654', lineHeight: 1.25 }}>* &nbsp;Peserta wajib redeem voucher dan verifikasi wajah di setiap sebelum sesi pelatihan </p>
            <p style={{
              fontSize: 12, color: '#394654', lineHeight: 1.25, marginLeft: '14px',
            }}
            >
              dan sebelum memulai post test.
            </p>

            <p style={{
              margin: '5px 0 0', fontSize: 12, color: '#394654', lineHeight: 1.25,
            }}
            >
              * &nbsp;Voucher hanya bisa di-redeem 1 jam sebelum kelas dimulai. Pastikan kamu
            </p>
            <p style={{
              fontSize: 12, color: '#394654', lineHeight: 1.25, marginLeft: '14px',
            }}
            >
              mengetahui jadwal kelas yang kamu pilih.
            </p>

            <p style={{
              margin: '5px 0 0', fontSize: 12, color: '#394654', lineHeight: 1.25,
            }}
            >
              * &nbsp;Kode voucher merupakan kode yang didapatkan dari digital platform tempat
            </p>
            <p style={{
              fontSize: 12, color: '#394654', lineHeight: 1.25, marginLeft: '14px',
            }}
            >
              peserta membeli pelatihan.
            </p>

            <p style={{
              margin: '5px 0 0', fontSize: 12, color: '#394654', lineHeight: 1.25,
            }}
            >
              * &nbsp;Kode redemption merupakan kode yang didapatkan dari dashboard prakerja
            </p>

            <p style={{
              margin: '5px 0 0', fontSize: 12, color: '#394654', lineHeight: 1.25,
            }}
            >
              * &nbsp;Masukkan angka tahapan kehadiran sesuai dengan sesi yang peserta ikuti untuk
            </p>
            <p style={{
              fontSize: 12, color: '#394654', lineHeight: 1.25, marginLeft: '14px',
            }}
            >
              presensi kehadiran.
            </p>

            <p style={{
              margin: '5px 0 0', fontSize: 12, color: '#394654', lineHeight: 1.25,
            }}
            >
              * &nbsp;Masukkan angka tahapan kehadiran 999 di akhir sesi untuk presensi post test
            </p>

          </div>
          <div css={redeemSupportStyle}>
            <span>
              Mengalami kendala saat me-redeem voucher?
              <a href="https://www.kode.id/pages/contact-us" target="_blank" rel="noopener noreferrer">Hubungi Kami</a>
            </span>
          </div>
        </div>
      </div>
    );
  }

  if (user && voucherRedemptionApiState === API_STATUS.SUCCESS) {
    return (
      <div css={rootContainerStyle}>
        <div css={contentContainerStyle} style={{ padding: 0 }}>
          <div css={redeemSuccessBannerStyle} />
          <div css={redeemSuccessContentContainerStyle}>
            <div css={redeemSuccessHeadingStyle}>
              Untuk melanjutkan pembelajaran, silahkan melakukan proses verifikasi wajah.
            </div>
            {/* <div css={redeemSuccessContentStyle}>
              Selamat, course
              <span>{` ${redeemedCourseTitle} `}</span>
              yang kamu ikuti telah berhasil diaktifkan.
            </div> */}
            <a href={redirectionUrl} css={linkNoEffectStyle}>
              <div
                css={startLearningButtonStyle}
                onClick={() => { }}
                onKeyUp={() => { }}
                role="button"
                tabIndex={0}
              >
                <span>Mulai Verifikasi</span>
              </div>
            </a>
          </div>
        </div>
      </div>
    );
  }

  return <div>error</div>; // TODO: dedicated error page
};

export default PrakerjaRedeem;
