import numeral from 'numeral';

const browser = {
  handleBack: () => {
    window.history.back();
  },
};

const slugify = (text) => text.toString().toLowerCase()
  .replace(/\s+/g, '-') // Replace spaces with -
  .replace(/[^\w-]+/g, '') // Remove all non-word chars
  .replace(/--+/g, '-') // Replace multiple - with single -
  .replace(/^-+/, '') // Trim - from start of text
  .replace(/-+$/, '');

const rupiahify = (amount) => `Rp. ${numeral(amount).format('0,0')}`;

const transformer = {
  slugify,
  rupiahify,
};

export { browser, transformer };
