/** @jsx jsx */
import { jsx } from '@emotion/core';

import {
  headerStyle,
  navStyle,
  logoStyle,
} from './Header.styles';

import Logo from '../assets/images/logo/kode-logo.png';
import { LEARN_WEB_LINK } from '../constants/links';
import SessionProvider from '../services/session';

export default () => {
  const user = SessionProvider.getSessionData();

  return (
    <header css={headerStyle}>
      <nav css={navStyle}>
        <a href={LEARN_WEB_LINK}>
          <img src={Logo} css={logoStyle} alt="kode.id" />
        </a>
        <div style={{ minHeight: 1, color: '#394654' }}>
          {user && user.firstName && (
            <span>
              Hai,&nbsp;
              <strong style={{ fontWeight: 700 }}>{user.firstName}</strong>
            </span>
          )}
        </div>
      </nav>
    </header>
  );
};
