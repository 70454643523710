import axios from 'axios';

import { KODE_PAYMENT_API_URL } from '../../configs/api';

// eslint-disable-next-line no-async-promise-executor
export const fetchCourseByThinkificId = (id) => new Promise(async (resolve, reject) => {
  try {
    const { data } = await axios({
      url: `${KODE_PAYMENT_API_URL}/v1/course-prices/thinkific/${id}`,
      method: 'get',
    });
    resolve(data.data);
  } catch (e) {
    reject(e);
  }
});

// eslint-disable-next-line no-async-promise-executor
export const validateVoucher = (voucherCode) => new Promise(async (resolve, reject) => {
  try {
    const { data } = await axios({
      url: `${KODE_PAYMENT_API_URL}/v1/single-use-vouchers/validate/${voucherCode}`,
      method: 'get',
    });
    resolve(data.data);
  } catch (e) {
    reject(e.response.data);
  }
});

// eslint-disable-next-line
export const redeemVoucher = (reqPayload) => new Promise(async (resolve, reject) => {
  try {
    const { data } = await axios({
      url: `${KODE_PAYMENT_API_URL}/v1/single-use-vouchers/redeem`,
      method: 'post',
      data: reqPayload,
    });

    resolve(data.data);
  } catch (e) {
    reject(e.response.data);
  }
});
