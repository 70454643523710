import axios from "axios";

import { KODE_PAYMENT_API_URL } from "../../configs/api";

// eslint-disable-next-line
export const submitSubmission = (payload) =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await axios({
        url: `${KODE_PAYMENT_API_URL}/v2/prakerja/submission/status`,
        method: "post",
        data: payload,
      });

      return resolve(data);
    } catch (e) {
      return reject(e.response.data);
    }
  });
