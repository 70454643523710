/** @jsx jsx */
import { jsx } from '@emotion/core';

import notFoundImage from '../../assets/images/sections/404web.png';

import {
  rootContainerStyle,
  headingStyle,
} from './NotFound.styles';

const NotFoundPage = () => (
  <div css={rootContainerStyle}>
    <h1 css={headingStyle}>404</h1>
    <img src={notFoundImage} alt="404" width="400px" />
  </div>
);

export default NotFoundPage;
