import { css } from '@emotion/core';

export const rootContainerStyle = css`
  background: #ebeced;
  display: flex;
  min-height: 100vh;
  padding-bottom: 150px;
  flex-direction: column;
  align-items: center;
  @media (max-width: 640px) {
    padding-bottom: 250px;
  }
`;

export const pageLinkStyle = css`
  font-weight: 600;
  color: #18b7bc;
  text-decoration: underline;
  :hover {
    color: #18b7bc;
  }
  :active {
    color: #18b7bc;
  }
`;

export const navbackLabelStyle = css`
  width: auto;
  font-size: 20px;
  font-weight: 700;
  margin: 0 10px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #394654;
`;

export const contentContainerStyle = css`
  display: flex;
  padding: 100px 20px 0 20px;
  max-width: 500px;
  flex-direction: column;
  align-items: center;
`;

export const limitedOfferContainerStyle = css`
  border-radius: 5px;
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #18b7bc;
  background-color: #e7f8f8;
  padding: 30px;
`;

export const limitedOfferStyle = css`
  font-size: 14px;
  width: 100%;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #18b7bc;
`;

export const courseContainerStyle = css`
  margin-top: 20px;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
`;

export const detailProductLabelStyle = css`
  margin: 30px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.14;
  letter-spacing: 1.4px;
  text-align: left;
  color: #394654;
`;

export const detailProductCourseLabelStyle = css`
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.14;
  letter-spacing: 1.4px;
  text-align: left;
  color: #394654;
  text-transform: uppercase;
`;

export const detailProductCourseTitleStyle = css`
  font-size: 18px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: normal;
  text-align: left;
  color: #394654;
`;

export const detailProductCourseContentStyle = css`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
  text-align: left;
  color: #394654;
`;

export const floatingCheckoutContainerStyle = css`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 15px 24px;
  background-color: #394654;
  display: flex;
  color: white;
  font-size: 14px;
  align-items: center;
  flex-direction: column;
`;

export const floatingCheckoutInnerContainerStyle = css`
  width: 100%;
  padding: 15px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  @media (max-width: 640px) {
    max-width: 500px;
    flex-direction: column;
  }
`;

export const checkoutSectionStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60px;
  line-height: 1.2rem;
  text-align: center;
  @media (max-width: 640px) {
    justify-content: center;
    align-items: center;
    line-height: 1.5rem;
  }
`;

export const checkoutCourseLabelStyle = css`
  color: #18b7bc;
  font-weight: 800;
`;

export const separatedCheckoutSectionStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  padding: 0 0px;
  margin-left: 20px;

  @media (min-width: 640px) {
    border-left: 1px solid rgba(255, 255, 255, 0.2);
  }

  @media (max-width: 640px) {
    width: 100%;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    margin: 10px 20px 0px 20px;
    padding-top: 15px;
  }
`;

export const promoCodeContainerStyles = css`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 0 0 20px;
  height: 60px;

  @media (max-width: 640px) {
    padding: 0;
    width: 100%;
  }
`;

export const verticalCheckoutInfoStyle = css`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 60px;
  padding: 0 20px;
  @media (max-width: 640px) {
    padding: 0;
    width: 100%;
  }
`;

export const checkoutButtonStyle = css`
  border-radius: 8px;
  background-color: #e65856;
  padding: 16px;
  color: #fff;
  border: 0;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: center;
  cursor: pointer;
  outline: none;
`;

export const needHelpStyle = css`
  color: #9ba2a9;
  font-size: 16px;
  margin: 10px;
  max-width: 500px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  padding: 10px;
`;

export const arrowBackStyle = css`
  cursor: pointer;
`;

export const labelStyles = css`
  display: block;
  margin: 0 0 5px 0;
  text-transform: capitalize;
  font-size: 14px;
`;

export const errorLabelStyles = css`
  display: block;
  margin: 5px 0 0 0;
  text-transform: capitalize;
  font-size: 8px;
  color: #e65856;
`;

export const promoCodeInputStyles = (isError) => css`
  width: 100%;
  height: 40px;
  padding: 0 15px;
  border-radius: 5px;
  border: 1px solid #000;
  border-color: ${isError ? '#e65856' : '#000'};
  text-transform: uppercase;
  outline: none;

  &::placeholder {
    text-transform: capitalize;
  }
`;
