import { css } from "@emotion/core";

export const tableContainerStyle = css`
  @media (min-width: 640px) {
    width: 700px;
    padding: 50px 60px;
  }
  border-collapse: collapse;
  width: 100%;
  margin-top: 25px;
  margin-bottom: 25px;

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
  }

  tr {
    text-align: center;
    background-color: white;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  tr:hover {
    background-color: #ddd;
    cursor: pointer;
  }

  th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: white;
    color: black;
    font-weight: bold;
  }
`;

export const notesStyle = css`
  width: 100%;
  background-color: white;
  margin-top: 20px;
  padding: 12px;
  margin-bottom: 20px;

  .title-container {
    text-align: left;
    font-weight: bold;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
  }

  .list {
    padding-top: 10px;
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    gap: 7px;
  }
`;
