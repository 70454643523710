export default class SessionProvider {
  static auth() {
    const sess = SessionProvider.getSessionData();

    // if session not set yet, getSessionData will yield null-value
    if (!sess) {
      return {
        isAuthenticated: false,
        message: 'Session not set',
      };
    }

    // if session exists, but invalid (might be due to bugs or user tampering data)
    if (!sess.id || !sess.email || !sess.firstName || !sess.lastName) {
      return {
        isAuthenticated: false,
        message: 'Session data invalid. Relog or clean up browser cache',
      };
    }

    return {
      isAuthenticated: true,
      message: 'Successfully authenticated',
    };
  }

  static getSessionData() {
    let kodeUserId = null;
    let kodeUserEmail = null;
    let kodeFirstName = null;
    let kodeLastName = null;

    try {
      // example: "kode_user_id=123123; kode_user_email=asd@asd.com; test1=Hello; test2=World"
      const cookieStr = document.cookie;
      const trimFrontSpace = (str) => {
        if (str[0] === ' ') {
          return str.slice(1);
        }

        return str;
      };

      const cookieStrSplit = cookieStr.split(';').map(trimFrontSpace);
      cookieStrSplit.forEach((cookie) => {
        if (cookie.includes('kode_user_id')) {
          [, kodeUserId] = cookie.split('=');
        }
        if (cookie.includes('kode_user_email')) {
          [, kodeUserEmail] = cookie.split('=');
        }
        if (cookie.includes('kode_user_first_name')) {
          [, kodeFirstName] = cookie.split('=');
        }
        if (cookie.includes('kode_user_last_name')) {
          [, kodeLastName] = cookie.split('=');
        }
      });

      return {
        id: kodeUserId,
        email: decodeURI(kodeUserEmail),
        firstName: decodeURI(kodeFirstName),
        lastName: decodeURI(kodeLastName),
      };
    } catch (e) {
      return null;
    }
  }
}
