/** @jsx jsx */
import { jsx } from '@emotion/core';

import {
  cardStyle,
  contentContainerStyle,
  logoStyle,
  rootContainerStyle,
  voucherInstructionLabelStyle,
} from './VoucherRedeem.styles';

import Logo from '../../assets/images/logo/kode-logo.png';

const Home = () => (
  <div css={rootContainerStyle}>
    <div css={contentContainerStyle}>
      <img src={Logo} css={logoStyle} alt="kode.id" />
      <span css={voucherInstructionLabelStyle}>Silakan Pilih Jenis Vouchermu Disini</span>
      <a href="/voucher-redeem-kode" css={cardStyle}>
        <span>Kode</span>
      </a>
      <a href="/voucher-redeem-prakerja" className="prakerja" css={cardStyle}>
        <span>Prakerja</span>
      </a>
    </div>
  </div>
);

export default Home;
