import axios from 'axios';

import { KODE_PAYMENT_API_URL } from '../../configs/api';

// eslint-disable-next-line
export const submitSubmission = (payload) => new Promise(async (resolve, reject) => {
  try {
    const { data } = await axios({
      url: `${KODE_PAYMENT_API_URL}/v2/prakerja/submission`,
      method: 'post',
      data: payload,
    });

    return resolve(data);
  } catch (e) {
    return reject(e.response.data);
  }
});

// eslint-disable-next-line
export const uploadFile = (file) => new Promise(async (resolve, reject) => {
  try {
    const formData = new FormData();

    formData.append('file', file);

    const { data } = await axios({
      url: 'https://api.clawnfox.com/v1/upload/prakerja',
      method: 'post',
      data: formData,
    });

    return resolve(data.link);
  } catch (e) {
    return reject(e.response.data);
  }
});
