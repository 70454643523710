export default {
  BLACK: '#000',
  WHITE: '#fff',
  KODE_RED: '#ff6360',
  KODE_PURPLE: '#645aff',
  KODE_LIGHT_GREY: '#e8edf2',
  KODE_GREY: '#f6f7fA',
  KODE_DARK_GREY: '#8393a3',
  KODE_CHARCOAL_GREY: '#394654',
};
