/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/button-has-type */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useState, useEffect } from "react";

import {
  rootContainerStyle,
  contentContainerStyle,
  logoStyle,
  voucherInstructionLabelStyle,
  voucherInputStyle,
  redeemVoucherButtonStyle,
  // redeemSupportStyle,
  errorMessageStyle,
  maintenanceModeContainerStyles,
  maintenanceModeContentStyles,
  maintenanceModeDescriptionContainerStyles,
  maintenanceModeTitleStyles,
  maintenanceModeDescriptionStyles,
  maintenanceModeImageStyles,
} from "../PrakerjaRedeem/PrakerjaRedeem.styles";

import { selectInputContainer } from "../SubmitPrakerjaSubmission/SubmitPrakerjaSubmission.styles";

import Logo from "../../assets/images/logo/kode-logo.png";
import MaintenanceMode from "../../assets/images/maintenance/maintenance.png";

import { API_STATUS } from "../../constants/api";

import SessionProviderSvc from "../../services/session";
import { tableContainerStyle } from "./PrakerjaScore.styles";
import { submitSubmission } from "./PrakerjaScore.services";

const PrakerjaScore = () => {
  // UI states
  const [scoreData, setScoreData] = useState(null);
  const [isShowScore, setIsShowScore] = useState(false);
  const [user, setUser] = useState(null);
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const [isVoucherCodeFormatValid, setIsVoucherCodeFormatValid] =
    useState(false);
  // const [voucherCode, setVoucherCode] = useState("");
  const [redemptionCode, setRedemptionCode] = useState("");
  const [sequence, setSequence] = useState("");
  const [scope, setScope] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);

  // API states
  const [voucherValidationApiState, setVoucherValidationApiState] = useState(
    API_STATUS.IDLE
  );
  const [voucherRedemptionApiState, setVoucherRedemptionApiState] = useState(
    API_STATUS.IDLE
  );
  const [isRedeemButtonDisabled, setRedeemButtonDisabled] = useState(
    !isVoucherCodeFormatValid && voucherValidationApiState === API_STATUS.IDLE
  );

  const voucherCodeCleaner = (v) => v.split("-").join("").split(" ").join("");

  const redemptionCodeLength = 13;
  const statusDecider = (status) => {
    if (status === 0) return "Belum Submit";
    if (status === 1) return "Pending Submit";
    if (status === 2) return "Sukses Submit";
    if (status === 3) return "Gagal Submit";
  };
  const handleSubmitSubmission = async () => {
    try {
      setVoucherRedemptionApiState(API_STATUS.LOADING);
      setIsFormDisabled(true);

      const resp = await submitSubmission({
        redemption_code: redemptionCode,
        sequence: Number(sequence),
        scope: scope.toLowerCase(),
      });

      if (!resp.success) {
        setErrorMessage(resp.message);
        setVoucherRedemptionApiState(API_STATUS.IDLE);
      } else {
        setVoucherRedemptionApiState(API_STATUS.SUCCESS);
        setScoreData(resp.data);
        setIsShowScore(true);
      }

      setIsFormDisabled(false);
    } catch (e) {
      setIsFormDisabled(false);

      setErrorMessage(
        e.message || "Ups, terjadi gangguan pada server. Mohon coba lagi."
      );
      setVoucherRedemptionApiState(API_STATUS.IDLE);
    }
  };

  const handleVoucherValidation = async () => {
    try {
      setVoucherValidationApiState(API_STATUS.LOADING);
      setIsFormDisabled(true);
      // await validateVoucher(cleanVoucherCode, cleanRedemptionCode);
      setIsFormDisabled(false);
      setVoucherValidationApiState(API_STATUS.SUCCESS);
      setErrorMessage(null);
      setIsFormDisabled(false);
      setRedeemButtonDisabled(false);
    } catch (e) {
      setIsFormDisabled(false);
      setErrorMessage(
        e.message || "Ups, terjadi gangguan pada server. Mohon coba lagi."
      );
      setVoucherValidationApiState(API_STATUS.IDLE);
      setRedeemButtonDisabled(true);
    }
  };

  const isRedemptionCodeValid = (value) => {
    const cleanValue = voucherCodeCleaner(value);
    const regex = new RegExp(/^[A-Z0-9]+$/i);
    const isValid = regex.test(cleanValue);

    return (
      (isValid || cleanValue === "") &&
      cleanValue.length <= redemptionCodeLength
    );
  };

  const isSequenceValid = () => sequence > 0;

  const handleSetRedemptionCode = (value) => {
    const cleanValue = value.slice(0, redemptionCodeLength).toUpperCase();

    if (isRedemptionCodeValid(cleanValue)) {
      setRedemptionCode(cleanValue);
    }
  };

  useEffect(() => {
    const redemptionCleanValue = voucherCodeCleaner(redemptionCode);

    if (
      (!isRedemptionCodeValid(redemptionCode) &&
        redemptionCleanValue.length === redemptionCodeLength) ||
      isSequenceValid()
    ) {
      setIsVoucherCodeFormatValid(true);
      handleVoucherValidation(redemptionCleanValue);
    }

    if (
      isRedemptionCodeValid(redemptionCode) &&
      redemptionCleanValue.length === redemptionCodeLength &&
      isSequenceValid()
    ) {
      handleVoucherValidation(redemptionCleanValue);
    }

    if (
      (isRedemptionCodeValid(redemptionCode) &&
        redemptionCleanValue.length < redemptionCodeLength) ||
      !isSequenceValid()
    ) {
      setIsVoucherCodeFormatValid(false);
    }
    // eslint-disable-next-line
  }, [redemptionCode, sequence]);

  useEffect(() => {
    setUser(SessionProviderSvc.getSessionData());
  }, []);

  if (process.env.REACT_APP_VOUCHER_REDEEM_PAGE_ENABLED === "false") {
    return (
      <div css={maintenanceModeContainerStyles}>
        <div css={maintenanceModeContentStyles}>
          <div css={maintenanceModeDescriptionContainerStyles}>
            <h1 css={maintenanceModeTitleStyles}>under maintenance</h1>
            <p css={maintenanceModeDescriptionStyles}>
              Kami sedang melakukan pemeliharaan sistem. Silahkan coba kembali
              beberapa saat.
            </p>
          </div>
          <img
            src={MaintenanceMode}
            css={maintenanceModeImageStyles}
            alt="maintenance-mode"
          />
        </div>
      </div>
    );
  }

  if (user) {
    return (
      <div css={rootContainerStyle}>
        <div css={contentContainerStyle}>
          <img src={Logo} css={logoStyle} alt="kode.id" />
          <span css={voucherInstructionLabelStyle}>
            Silakan cek nilaimu disini
          </span>

          <input
            className="second-input"
            css={voucherInputStyle}
            type="text"
            value={redemptionCode}
            onChange={(e) => handleSetRedemptionCode(e.target.value)}
            disabled={isFormDisabled}
            style={{
              letterSpacing: redemptionCode === "" ? 0 : "1.4px",
              borderColor: errorMessage
                ? "#e65856"
                : voucherValidationApiState === API_STATUS.SUCCESS
                ? "#18b7bc"
                : "#ebeced",
            }}
            placeholder="Masukkan 12 digit kode redemption"
          />
          <input
            className="second-input"
            css={voucherInputStyle}
            type="number"
            value={sequence}
            onChange={(e) => setSequence(e.target.value)}
            disabled={isFormDisabled}
            style={{
              letterSpacing: redemptionCode === "" ? 0 : "1.4px",
              borderColor: errorMessage
                ? "#e65856"
                : voucherValidationApiState === API_STATUS.SUCCESS
                ? "#18b7bc"
                : "#ebeced",
            }}
            placeholder="Masukkan tahapan kehadiran"
          />

          <select
            css={selectInputContainer}
            className="second-input"
            value={scope}
            onChange={(e) => setScope(e.target.value)}
            disabled={isFormDisabled}
            style={{
              letterSpacing: redemptionCode === "" ? 0 : "1.4px",
              borderColor: errorMessage
                ? "#e65856"
                : voucherValidationApiState === API_STATUS.SUCCESS
                ? "#18b7bc"
                : "#ebeced",
            }}
          >
            <option disabled selected value="">
              Masukkan Scope
            </option>{" "}
            {/* Placeholder */}
            <option>TPM</option>
            <option>UK</option>
          </select>

          {errorMessage && <div css={errorMessageStyle}>{errorMessage}</div>}
          <div
            css={redeemVoucherButtonStyle}
            onClick={isRedeemButtonDisabled ? () => {} : handleSubmitSubmission}
            onKeyUp={() => {}}
            role="button"
            tabIndex={0}
            style={{
              cursor: isRedeemButtonDisabled ? "not-allowed" : "pointer",
              background: isRedeemButtonDisabled ? "#9ba2a9" : "#e65856",
            }}
          >
            <span style={{ fontWeight: 700 }}>
              {voucherValidationApiState === API_STATUS.LOADING ||
              voucherRedemptionApiState === API_STATUS.LOADING
                ? "Loading..."
                : "Submit"}
            </span>
          </div>
        </div>

        {isShowScore && voucherRedemptionApiState !== API_STATUS.LOADING && (
          <div>
            <table id="customers" css={tableContainerStyle}>
              <tr>
                <th>No</th>
                <th>Redemption Code</th>
                <th>Scope</th>
                <th>Sesi</th>
                <th>Status</th>
                <th>Description</th>
                <th>Notes</th>
              </tr>
              <tr>
                <td>1</td>
                <td>{scoreData?.status?.data?.assignment.redeem_code}</td>
                <td>{scoreData?.status?.data?.assignment.scope}</td>
                <td>{scoreData?.status?.data?.assignment.sequence}</td>
                <td>
                  {statusDecider(scoreData?.status?.data?.assignment.status)}
                </td>
                <td>Assignment</td>
                <td></td>
              </tr>
              <tr>
                <td>2</td>
                <td>{scoreData?.status?.data?.feedback.redeem_code}</td>
                <td>{scoreData?.status?.data?.feedback.scope}</td>
                <td>{scoreData?.status?.data?.feedback.sequence}</td>
                <td>
                  {statusDecider(scoreData?.status?.data?.assignment.status)}
                </td>
                <td>Feedback</td>
                <td></td>
              </tr>
            </table>
          </div>
        )}
      </div>
    );
  }

  //   if (user && voucherRedemptionApiState === API_STATUS.SUCCESS) {
  //     return (
  //       <div css={rootContainerStyle}>
  //         <div
  //           css={contentContainerStyle}
  //           style={{ padding: 0, minHeight: "15vh" }}
  //         >
  //           <div css={redeemSuccessBannerStyle} />
  //           <div css={redeemSuccessContentContainerStyle}>
  //             <div css={redeemSuccessHeadingStyle}>
  //               Submission telah berhasil dikirim.
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     );
  //   }

  return <div>error</div>; // TODO: dedicated error page
};

export default PrakerjaScore;
