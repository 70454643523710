import { css } from '@emotion/core';

import Cover from '../../assets/images/sections/redeemcover.jpg';
import SuccessRedeem from '../../assets/images/sections/successredeem.png';
import { colors } from '../../configs';

export const rootContainerStyle = css`
  background: url(${Cover}), #ebeced;
  background-size: cover;
  display: flex;
  min-height: 100vh;
  padding: 0px 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const logoStyle = css`
  margin-bottom: 25px;
  width: 150px;
`;

export const contentContainerStyle = css`
  display: flex;
  flex: 0;
  min-height: 40vh;
  margin-top: 40px;
  padding: 20px 20px;
  @media (min-width: 640px) {
    width: 600px;
    height: 475px;
    margin-top: 0;
    padding: 50px 60px;
  }

  background: #ffffff;
  border-radius: 10px;
  flex-direction: column;
  align-items: center;
`;

export const voucherInstructionLabelStyle = css`
  font-weight: 700;
  font-size: 14px;
  margin: 25px 0;
  color: #394654;
`;

export const voucherInputStyle = css`
  padding: 10px 20px;
  border: 2px solid #ebeced;
  letter-spacing: 2.8px;
  border-radius: 10px;
  text-align: center;
  outline: none;
  width: 100%;
  font-size: 14px;
  line-height: 40px;
  color: #394654;

  &:focus {
    border-color: #394654;
  }
  &.second-input {
    margin: 20px 0 0;
  }
`;

export const redeemVoucherButtonStyle = css`
  border-radius: 30px;
  background-color: #9ba2a9;
  padding: 16px 33px;
  font-size: 20px;
  border: 0;
  outline: 0;
  margin-top: 32px;
  cursor: pointer;
  & span {
    font-weight: 700 !important;
    color: #ffffff;
  }
`;

export const redeemSupportStyle = css`
  font-size: 14px;
  margin-top: 25px;
  color: #394654;
  line-height: 1.2rem;
  text-align: center;

  a {
    margin: 0 5px;
    font-weight: 700;
    text-decoration: underline;
    color: #18b7bc;
  }
`;

export const errorMessageStyle = css`
  color: #e65856;
  font-size: 28px;
  margin: 10px 0;
`;

export const redeemSuccessBannerStyle = css`
  background: url(${SuccessRedeem});
  width: 100%;
  height: 305px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-size: cover;
`;

export const redeemSuccessContentContainerStyle = css`
  padding: 30px 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const redeemSuccessHeadingStyle = css`
  color: #394654;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 30px;
  text-align: center;
`;

export const redeemSuccessContentStyle = css`
  color: #394654;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  span {
    color: #18b7bc;
    font-weight: 700;
  }
`;

export const startLearningButtonStyle = css`
  border-radius: 30px;
  background-color: #e65856;
  padding: 16px 33px;
  font-size: 20px;
  border: 0;
  outline: 0;
  margin-top: 32px;
  cursor: pointer;
  text-align: center;
  & span {
    font-weight: 700 !important;
    color: #ffffff;
  }
`;

export const linkNoEffectStyle = css`
  text-decoration: none !important;
`;

export const maintenanceModeContainerStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 80px);
  margin: 80px 0 0 0;
`;

export const maintenanceModeContentStyles = css`
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 30px 15px;

  @media screen and (min-width: 1200px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 1200px;
    padding: 0;
  }
`;

export const maintenanceModeDescriptionContainerStyles = css`
  width: 100%;
  margin: 30px 0 0 0;

  @media screen and (min-width: 1200px) {
    width: 50%;
    margin: 0;
  }
`;

export const maintenanceModeTitleStyles = css`
  text-transform: capitalize;
  font-weight: 700;
  text-align: center;
  font-size: 28px;
  color: ${colors.KODE_CHARCOAL_GREY};

  @media screen and (min-width: 1200px) {
    text-align: left;
    font-size: 50px;
  }
`;

export const maintenanceModeDescriptionStyles = css`
  margin: 35px 0 0 0;
  text-align: center;
  line-height: 1.5;
  font-size: 16px;
  color: ${colors.KODE_CHARCOAL_GREY};

  &:first-letter {
    text-transform: uppercase;
  }

  @media screen and (min-width: 1200px) {
    text-align: left;
    font-size: 24px;
  }
`;

export const maintenanceModeImageStyles = css`
  width: 100%;
  max-width: 600px;
  height: auto;

  @media screen and (min-width: 1200px) {
    width: 50%;
    max-width: unset;
  }
`;
