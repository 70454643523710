import axios from 'axios';

import { KODE_PAYMENT_API_URL } from '../../configs/api';

export const fetchCourseByThinkificId = (id) => axios({
  url: `${KODE_PAYMENT_API_URL}/v1/course-prices/thinkific/${id}`,
  method: 'get',
});

// eslint-disable-next-line
export const callbackState = (state) => new Promise(async (resolve, reject) => {
  try {
    // return resolve({ url: 'https://www.kode.id/courses/Belajar-Langkah-Fundamental-Untuk-Menjadi-Web-Programmer' });
    const { data } = await axios({
      url: `${KODE_PAYMENT_API_URL}/v2/prakerja/voucher/redeem`,
      method: 'post',
      data: { state },
    });

    return resolve(data.data);
  } catch (e) {
    return reject(e.response.data);
  }
});
