import GTM from './GTM';
// import FrontAppLiveChat from './FrontAppLiveChat';

export {
  GTM,
  // FrontAppLiveChat,
};

class ExternalVendor {
  static init() {
    GTM.init();
    // FrontAppLiveChat.init();
  }
}

export default ExternalVendor;
