import axios from 'axios';

import { KODE_PAYMENT_API_URL } from '../../configs/api';

export const fetchCourseByThinkificId = (id) => axios({
  url: `${KODE_PAYMENT_API_URL}/v1/course-prices/thinkific/${id}`,
  method: 'get',
});

// eslint-disable-next-line no-async-promise-executor
// export const validateVoucher = (voucherCode, redCode) => new Promise(async (resolve, reject) => {
//   try {
//     const { data } = await axios({
//       url: `${KODE_PAYMENT_API_URL}/v1/prakerja/voucher/validate`,
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       method: 'post',
//       data: {
//         voucher_code: voucherCode,
//         redemption_code: redCode,
//       },
//     });
//     resolve(data);
//   } catch (e) {
//     reject(e.response.data);
//   }
// });

// eslint-disable-next-line
export const redeemVoucher = (payload) => new Promise(async (resolve, reject) => {
  try {
    // return resolve({ url: 'https://oauth.prakerja.go.id/consent?state=STATE-1Z1FFG' });
    const { data } = await axios({
      url: `${KODE_PAYMENT_API_URL}/v2/prakerja/voucher/validate`,
      method: 'post',
      data: payload,
    });

    return resolve(data.data);
  } catch (e) {
    return reject(e.response.data);
  }
});
