import { css } from '@emotion/core';

import Cover from '../../assets/images/sections/redeemcover.jpg';
import { colors } from '../../configs';

export const rootContainerStyle = css`
  background: url(${Cover}), #ebeced;
  background-size: cover;
  display: flex;
  min-height: 100vh;
  padding: 0px 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const logoStyle = css`
  margin-bottom: 25px;
  width: 150px;
`;

export const contentContainerStyle = css`
  display: flex;
  flex: 0;
  min-height: 40vh;
  margin-top: 40px;
  padding: 20px 20px;
  @media (min-width: 640px) {
    width: 600px;
    height: 475px;
    margin-top: 0;
    padding: 50px 60px;
  }

  background: #ffffff;
  border-radius: 10px;
  flex-direction: column;
  align-items: center;
`;

export const voucherInstructionLabelStyle = css`
  font-weight: 700;
  font-size: 14px;
  margin: 25px 0;
  color: #394654;
`;

export const cardStyle = css`
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  border-radius: 15px;
  background-color: ${colors.KODE_RED};
  padding: 16px 33px;
  font-size: 20px;
  border: 0;
  outline: 0;
  margin-top: 32px;
  cursor: pointer;
  & span {
    font-weight: 700 !important;
    color: #ffffff;
  }
`;
