/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useEffect, useState } from 'react';
// import { withRouter } from 'react-router-dom';

import {
  maintenanceModeContainerStyles,
  errorMessageStyle,
  // maintenanceModeContentStyles,
  // maintenanceModeDescriptionContainerStyles,
  // maintenanceModeTitleStyles,
  maintenanceModeDescriptionStyles,
  // maintenanceModeImageStyles,
} from './PrakerjaCallback.styles';

// import MaintenanceMode from '../../assets/images/maintenance/maintenance.png';

import { callbackState } from './PrakerjaCallback.services';
import { API_STATUS } from '../../constants/api';

const PrakerjaCallback = () => {
  // const [redirectionUrl, setRedirectionUrl] = useState(null);
  const [callbackApiState, setCallbackApiState] = useState(API_STATUS.IDLE);
  const [errorMessage, setErrorMessage] = useState(null);

  const { search } = window.location;
  const params = new URLSearchParams(search);
  const state = params.get('state');

  useEffect(() => {
    const handleCallbackState = async () => {
      try {
        setCallbackApiState(API_STATUS.LOADING);

        const resp = await callbackState(state);

        // setRedirectionUrl(resp.url);
        window.location.replace(resp.url);
        setCallbackApiState(API_STATUS.SUCCESS);
      } catch (e) {
        setErrorMessage(e.message || 'Ups, terjadi gangguan pada server. Mohon coba lagi.');
        setCallbackApiState(API_STATUS.IDLE);
      }
    };

    handleCallbackState();
  }, []);

  return (
    <div css={maintenanceModeContainerStyles}>
      {errorMessage ? <div css={errorMessageStyle}>{errorMessage}</div> : (
        <p css={maintenanceModeDescriptionStyles}>
          {callbackApiState === API_STATUS.LOADING ? 'Checking...' : 'Redirecting...'}
        </p>
      )}
    </div>
  );
};

export default PrakerjaCallback;
