/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useLayoutEffect } from 'react';

import {
  rootContainerStyle,
  contentContainerStyle,
} from './ProcessPayment.styles';

/* istanbul ignore next */
function redirectTo(url) {
  window.location.replace(url);
}

const ProcessPayment = () => {
  useLayoutEffect(() => {
    /* istanbul ignore next */
    const timer = setTimeout(() => {
      const query = new URLSearchParams(window.location.search);
      const url = query.get('url');
      /* istanbul ignore next */
      redirectTo(url);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div css={rootContainerStyle}>
      <div css={contentContainerStyle}>
        <h1> Redirecting .... </h1>
      </div>
    </div>
  );
};

export default ProcessPayment;
