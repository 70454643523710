/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useState, useEffect } from 'react';
import FA from 'react-fontawesome';

import { API_STATUS } from '../../constants/api';
import { browser, transformer } from '../../helpers';
import SessionProvider from '../../services/session';

import * as css from './SinglePurchaseCheckout.styles';
import {
  fetchCoursePriceById,
  checkPromoCode,
  createInvoice,
} from './SinglePurchaseCheckout.services';

const SinglePurchaseCheckout = (p) => {
  const courseThinkificID = p.match.params.id;
  const [user, setUser] = useState(null);
  const [coursePrice, setCoursePrice] = useState({});
  const [basePrice, setBasePrice] = useState(0);
  const [coursePriceStatusEnum, setCoursePriceStatusEnum] = useState(
    API_STATUS.IDLE,
  );
  const [coursePriceErr, setCoursePriceErr] = useState(null);
  const [promoCode, setPromoCode] = useState('');
  const [promoCodeRes, setPromoCodeRes] = useState({
    isValid: false,
    discount: 0,
    message: '',
  });

  const handleCheckoutClick = async () => {
    try {
      const payload = {
        course_prices_id: coursePrice.id,
        external_id: user.id,
        thinkific_id: 'NOT SPECIFIED',
        email: user.email,
        first_name: user.firstName,
        last_name: user.lastName,
        amount: coursePrice.base_price,
      };

      if (promoCodeRes.isValid) payload.promo_code = promoCode;

      const response = await createInvoice(payload);
      window.location
        .assign(`/process-payment?url=${response.invoice_external_url}
      &course_id=${coursePrice.thinkific_course_external_id}&amount=${coursePrice.base_price}`);
      // eslint-disable-next-line no-empty
    } catch (e) {}
  };

  const handlePromoCodeChange = (e) => {
    setPromoCode(e.target.value);
  };

  useEffect(() => {
    const handleCheckPromoCode = async () => {
      try {
        const res = await checkPromoCode(promoCode.toLowerCase());

        setPromoCodeRes({
          isValid: res.data.valid,
          discount: res.data.data.discount,
          message: res.data.message,
        });
        // eslint-disable-next-line no-empty
      } catch (e) {
        setPromoCodeRes({
          isValid: e.response.data.valid,
          discount: e.response.data.data.discount,
          message: e.response.data.message,
        });
      }
    };

    if (promoCode.length === 12) {
      handleCheckPromoCode();
    } else {
      setCoursePrice((prevState) => ({
        ...prevState,
        base_price: basePrice + promoCodeRes.discount,
      }));
      setPromoCodeRes({
        isValid: false,
        discount: 0,
        message: '',
      });
    }
  }, [promoCode, basePrice, promoCodeRes.discount]);

  useEffect(() => {
    setUser(SessionProvider.getSessionData());
    setCoursePriceStatusEnum(API_STATUS.PENDING);

    const resolve = (data) => {
      setCoursePrice(data);
      setBasePrice(data.base_price);
      setCoursePriceStatusEnum(API_STATUS.SUCCESS);
    };

    const reject = (e) => {
      setCoursePriceStatusEnum(API_STATUS.FAILURE);
      setCoursePriceErr(e.message);
    };

    fetchCoursePriceById(courseThinkificID, resolve, reject);
    // eslint-disable-next-line
  }, []);

  if (user) {
    return (
      <div css={css.rootContainerStyle}>
        {coursePriceStatusEnum === 'PENDING' && (
          <div style={{ paddingTop: 200 }}>memuat data...</div>
        )}
        {coursePriceStatusEnum === 'FAILURE' && (
          <div data-testid="course_price_error" style={{ paddingTop: 200 }}>
            {coursePriceErr || 'Oops, terjadi gangguan teknis.'}
          </div>
        )}
        {coursePriceStatusEnum === 'SUCCESS' && (
          <div css={css.contentContainerStyle}>
            <div style={{ width: '100%', marginTop: 10 }}>
              {/* eslint-disable-next-line */}
              <div
                data-testid="navigate_back"
                css={css.arrowBackStyle}
                onClick={browser.handleBack}
              >
                <FA
                  name="arrow-left"
                  style={{ fontSize: 16, color: '#18b7bc' }}
                />
                <span css={css.navbackLabelStyle}>One-Time Purchase</span>
              </div>
            </div>
            <div css={css.courseContainerStyle}>
              <div css={css.detailProductLabelStyle}>Detail produk</div>
              <img
                alt="cov"
                src={coursePrice.kode_course_image_url}
                width="100%"
              />
              <div style={{ padding: 30 }}>
                <div css={css.detailProductCourseLabelStyle}>course</div>
                <h2 css={css.detailProductCourseTitleStyle}>
                  {coursePrice.kode_course_title}
                </h2>
                <div style={{ height: 30 }} />
                <div css={css.detailProductCourseLabelStyle}>description</div>
                <div style={{ height: 10 }} />
                <div css={css.detailProductCourseContentStyle}>
                  {coursePrice.kode_course_description}
                </div>
              </div>
            </div>
          </div>
        )}
        {coursePriceStatusEnum === 'SUCCESS' && (
          <div css={css.floatingCheckoutContainerStyle}>
            <div css={css.floatingCheckoutInnerContainerStyle}>
              <div css={css.checkoutSectionStyle}>
                Butuh bantuan untuk menyelesaikan pembayaran?
                <br />
                <a href="mailto:support@hacktiv8.com" css={css.pageLinkStyle}>
                  Hubungi Kami
                </a>
              </div>
              <div css={css.separatedCheckoutSectionStyle}>
                <div css={css.promoCodeContainerStyles}>
                  <span css={css.labelStyles}>apply promo code</span>
                  <input
                    type="text"
                    css={css.promoCodeInputStyles(promoCodeRes.message === 'Promo tidak valid')}
                    name="promoCode"
                    value={promoCode}
                    onChange={handlePromoCodeChange}
                    placeholder="enter promo code"
                  />
                  {promoCodeRes.message === 'Promo tidak valid' && (
                    <span css={css.errorLabelStyles}>{promoCodeRes.message}</span>
                  )}
                </div>
              </div>
              {promoCodeRes.isValid && (
                <div css={css.separatedCheckoutSectionStyle}>
                  <div css={css.promoCodeContainerStyles}>
                    <span css={css.labelStyles}>promo code discount</span>
                    <div
                      style={{ marginTop: 5, fontSize: 24, fontWeight: 'bold' }}
                    >
                      {transformer.rupiahify(promoCodeRes.discount)}
                    </div>
                  </div>
                </div>
              )}
              <div css={css.separatedCheckoutSectionStyle}>
                <div css={css.verticalCheckoutInfoStyle}>
                  <div style={{ fontSize: 14 }}>Total</div>
                  <div
                    data-testid="checkout_course_price"
                    style={{ marginTop: 5, fontSize: 24, fontWeight: 'bold' }}
                  >
                    {transformer.rupiahify(coursePrice.base_price - promoCodeRes.discount)}
                  </div>
                </div>
                <button
                  data-testid="course_purchase_checkout"
                  css={css.checkoutButtonStyle}
                  type="button"
                  onClick={handleCheckoutClick}
                >
                  Checkout
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  return <div>error</div>; // TODO: dedicated error page
};

export default SinglePurchaseCheckout;
