import { css } from '@emotion/core';

export const rootContainerStyle = css`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const headingStyle = css`
  font-size: 3rem;
  font-weight: 600;
  margin: 20;
`;
