/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/button-has-type */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useState, useEffect } from 'react';
import upload from '../../assets/icons/upload.png';

import {
  rootContainerStyle,
  contentContainerStyle,
  logoStyle,
  voucherInstructionLabelStyle,
  voucherInputStyle,
  redeemVoucherButtonStyle,
  // redeemSupportStyle,
  errorMessageStyle,
  redeemSuccessBannerStyle,
  redeemSuccessContentContainerStyle,
  redeemSuccessHeadingStyle,
  maintenanceModeContainerStyles,
  maintenanceModeContentStyles,
  maintenanceModeDescriptionContainerStyles,
  maintenanceModeTitleStyles,
  maintenanceModeDescriptionStyles,
  maintenanceModeImageStyles,
} from '../PrakerjaRedeem/PrakerjaRedeem.styles';

import { uploadFile, submitSubmission } from './SubmitPrakerjaSubmission.services';
import {
  buttonCancel,
  buttonFileStyle,
  containerFileStyle,
  fileInputStyle,
  selectInputContainer,
  textFileNameStyle,
} from './SubmitPrakerjaSubmission.styles';

import Logo from '../../assets/images/logo/kode-logo.png';
import MaintenanceMode from '../../assets/images/maintenance/maintenance.png';

import { API_STATUS } from '../../constants/api';

import SessionProviderSvc from '../../services/session';

const SubmitPrakerjaSubmission = () => {
  const [fileName, setFileName] = useState('');
  const [file, setFile] = useState(null);
  const handleFileInputChange = (e) => {
    const f = e.target.files[0];
    setFile(f || null);
    setFileName(f ? f.name : '');
  };

  // UI states
  const [user, setUser] = useState(null);
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const [isVoucherCodeFormatValid, setIsVoucherCodeFormatValid] = useState(false);
  const [voucherCode, setVoucherCode] = useState('');
  const [redemptionCode, setRedemptionCode] = useState('');
  const [sequence, setSequence] = useState('');
  const [scope, setScope] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [disabledSequence, setIsDisabledSequence] = useState(false)

  // API states
  const [voucherValidationApiState, setVoucherValidationApiState] = useState(
    API_STATUS.IDLE,
  );
  const [voucherRedemptionApiState, setVoucherRedemptionApiState] = useState(
    API_STATUS.IDLE,
  );
  const [isRedeemButtonDisabled, setRedeemButtonDisabled] = useState(
    !isVoucherCodeFormatValid && voucherValidationApiState === API_STATUS.IDLE,
  );

  const voucherCodeCleaner = (v) => v.split('-').join('').split(' ').join('');
  const voucherCodeLength = 12;
  const redemptionCodeLength = 13;

  const handleSubmitSubmission = async () => {
    try {
      setVoucherRedemptionApiState(API_STATUS.LOADING);
      setIsFormDisabled(true);

      const urlFile = await uploadFile(file);

      const resp = await submitSubmission({
        external_id: user.id,
        thinkific_id: 'NOT SPECIFIED',
        first_name: user.firstName,
        last_name: user.lastName,
        email: user.email,
        voucher_code: voucherCodeCleaner(voucherCode).toLowerCase(),
        redemption_code: redemptionCode,
        sequence: Number(sequence),
        scope: scope.toLowerCase(),
        url_file: urlFile,
      });

      if (!resp.success) {
        setErrorMessage(resp.message);
        setVoucherRedemptionApiState(API_STATUS.IDLE);
      } else {
        setVoucherRedemptionApiState(API_STATUS.SUCCESS);
      }

      setIsFormDisabled(false);
    } catch (e) {
      setIsFormDisabled(false);

      setErrorMessage(
        e.message || 'Ups, terjadi gangguan pada server. Mohon coba lagi.',
      );
      setVoucherRedemptionApiState(API_STATUS.IDLE);
    }
  };

  // const handleVoucherValidation = async (cleanVoucherCode, cleanRedemptionCode) => {
  const handleVoucherValidation = async () => {
    try {
      setVoucherValidationApiState(API_STATUS.LOADING);
      setIsFormDisabled(true);
      // await validateVoucher(cleanVoucherCode, cleanRedemptionCode);
      setIsFormDisabled(false);
      setVoucherValidationApiState(API_STATUS.SUCCESS);
      setErrorMessage(null);
      setIsFormDisabled(false);
      setRedeemButtonDisabled(false);
    } catch (e) {
      setIsFormDisabled(false);
      setErrorMessage(
        e.message || 'Ups, terjadi gangguan pada server. Mohon coba lagi.',
      );
      setVoucherValidationApiState(API_STATUS.IDLE);
      setRedeemButtonDisabled(true);
    }
  };

  const isVoucherCodeValid = (value) => {
    const cleanValue = voucherCodeCleaner(value);
    const regex = new RegExp(/^[a-z0-9]+$/i);
    const isValid = regex.test(cleanValue);

    return (
      (isValid || cleanValue === '') && cleanValue.length <= voucherCodeLength
    );
  };

  const isRedemptionCodeValid = (value) => {
    const cleanValue = voucherCodeCleaner(value);
    const regex = new RegExp(/^[A-Z0-9]+$/i);
    const isValid = regex.test(cleanValue);

    return (
      (isValid || cleanValue === '')
      && cleanValue.length <= redemptionCodeLength
    );
  };

  const isSequenceValid = () => sequence > 0;

  const handleSetVoucherCode = (value) => {
    const cleanValue = value.slice(0, voucherCodeLength).toUpperCase();

    if (isVoucherCodeValid(value)) {
      setVoucherCode(cleanValue);
    }
  };

  const handleSetRedemptionCode = (value) => {
    const cleanValue = value.slice(0, redemptionCodeLength).toUpperCase();

    if (isRedemptionCodeValid(cleanValue)) {
      setRedemptionCode(cleanValue);
    }
  };

  useEffect(() => {
    const voucherCleanValue = voucherCodeCleaner(voucherCode);
    const redemptionCleanValue = voucherCodeCleaner(redemptionCode);

    if (
      (!isVoucherCodeValid(voucherCode)
        && voucherCleanValue.length === voucherCodeLength)
      || (!isRedemptionCodeValid(redemptionCode)
        && redemptionCleanValue.length === redemptionCodeLength)
      || isSequenceValid()
    ) {
      setIsVoucherCodeFormatValid(true);
      handleVoucherValidation(voucherCleanValue, redemptionCleanValue);
    }

    if (
      isVoucherCodeValid(voucherCode)
      && voucherCleanValue.length === voucherCodeLength
      && isRedemptionCodeValid(redemptionCode)
      && redemptionCleanValue.length === redemptionCodeLength
      && isSequenceValid()
    ) {
      handleVoucherValidation(voucherCleanValue, redemptionCleanValue);
    }

    if (
      (isVoucherCodeValid(voucherCode)
        && voucherCleanValue.length < voucherCodeLength)
      || (isRedemptionCodeValid(redemptionCode)
        && redemptionCleanValue.length < redemptionCodeLength)
      || !isSequenceValid()
    ) {
      setIsVoucherCodeFormatValid(false);
    }
    // eslint-disable-next-line
  }, [voucherCode, redemptionCode, sequence]);

  useEffect(() => {
    setUser(SessionProviderSvc.getSessionData());
  }, []);

  useEffect(()=> {
    if (scope === "UK") {
      setIsDisabledSequence(true)
      setSequence("999")
    }else {
      setIsDisabledSequence(false)
      setSequence("")
    }
  },[scope])

  if (process.env.REACT_APP_VOUCHER_REDEEM_PAGE_ENABLED === 'false') {
    return (
      <div css={maintenanceModeContainerStyles}>
        <div css={maintenanceModeContentStyles}>
          <div css={maintenanceModeDescriptionContainerStyles}>
            <h1 css={maintenanceModeTitleStyles}>under maintenance</h1>
            <p css={maintenanceModeDescriptionStyles}>
              Kami sedang melakukan pemeliharaan sistem. Silahkan coba kembali
              beberapa saat.
            </p>
          </div>
          <img
            src={MaintenanceMode}
            css={maintenanceModeImageStyles}
            alt="maintenance-mode"
          />
        </div>
      </div>
    );
  }

  if (user && voucherRedemptionApiState !== API_STATUS.SUCCESS) {
    return (
      <div css={rootContainerStyle}>
        <div css={contentContainerStyle}>
          <img src={Logo} css={logoStyle} alt="kode.id" />
          <span css={voucherInstructionLabelStyle}>
            Silakan Submit Submissionmu Disini
          </span>
          <input
            css={voucherInputStyle}
            type="text"
            value={voucherCode}
            onChange={(e) => handleSetVoucherCode(e.target.value)}
            disabled={isFormDisabled}
            style={{
              letterSpacing: voucherCode === '' ? 0 : '2.8px',
              borderColor: errorMessage
                ? '#e65856'
                : voucherValidationApiState === API_STATUS.SUCCESS
                  ? '#18b7bc'
                  : '#ebeced',
            }}
            placeholder="Masukan 12 digit kode voucher"
          />
          <input
            className="second-input"
            css={voucherInputStyle}
            type="text"
            value={redemptionCode}
            onChange={(e) => handleSetRedemptionCode(e.target.value)}
            disabled={isFormDisabled}
            style={{
              letterSpacing: redemptionCode === '' ? 0 : '1.4px',
              borderColor: errorMessage
                ? '#e65856'
                : voucherValidationApiState === API_STATUS.SUCCESS
                  ? '#18b7bc'
                  : '#ebeced',
            }}
            placeholder="Masukkan 12 digit kode redemption"
          />
            <select
            css={selectInputContainer}
            className="second-input"
            value={scope}
            onChange={(e) => setScope(e.target.value)}
            disabled={isFormDisabled}
            style={{
              letterSpacing: redemptionCode === '' ? 0 : '1.4px',
              borderColor: errorMessage
                ? '#e65856'
                : voucherValidationApiState === API_STATUS.SUCCESS
                  ? '#18b7bc'
                  : '#ebeced',
            }}
          >
            <option disabled selected value="">
              Masukkan Scope
            </option>
            {' '}
            {/* Placeholder */}
            <option>TPM</option>
            <option>UK</option>
          </select>
          <input

            className="second-input"
            css={voucherInputStyle}
            type="number"
            value={sequence}
            onChange={(e) => setSequence(e.target.value)}
            disabled={isFormDisabled || disabledSequence}
            style={{
              letterSpacing: redemptionCode === '' ? 0 : '1.4px',
              borderColor: errorMessage
                ? '#e65856'
                : voucherValidationApiState === API_STATUS.SUCCESS
                  ? '#18b7bc'
                  : '#ebeced',
            }}
            placeholder="Masukkan tahapan kehadiran"
          />



          <div
            css={containerFileStyle}
            style={{
              borderColor: errorMessage
                ? '#e65856'
                : voucherValidationApiState === API_STATUS.SUCCESS
                  ? '#18b7bc'
                  : '#ebeced',
              justifyContent: 'center',
            }}
          >
            <img src={upload} alt="" style={{ width: '50px' }} />
            <input
              type="file"
              id="fileInput"
              css={fileInputStyle}
              hidden
              onChange={handleFileInputChange}
            />
            <button
              css={buttonFileStyle}
              onClick={() => document.getElementById('fileInput').click()}
            >
              Choose File
            </button>
            <span css={textFileNameStyle}>
              {fileName}
              {' '}
              {fileName && (
                <span css={buttonCancel} onClick={() => setFile(null)}>
                  X
                </span>
              )}
            </span>
            {/* Tampilkan nama file yang dipilih */}
          </div>

          {errorMessage && <div css={errorMessageStyle}>{errorMessage}</div>}
          <div
            css={redeemVoucherButtonStyle}
            onClick={isRedeemButtonDisabled ? () => {} : handleSubmitSubmission}
            onKeyUp={() => {}}
            role="button"
            tabIndex={0}
            style={{
              cursor: isRedeemButtonDisabled ? 'not-allowed' : 'pointer',
              background: isRedeemButtonDisabled ? '#9ba2a9' : '#e65856',
            }}
          >
            <span style={{ fontWeight: 700 }}>
              {voucherValidationApiState === API_STATUS.LOADING
              || voucherRedemptionApiState === API_STATUS.LOADING
                ? 'Loading...'
                : 'Submit'}
            </span>
          </div>
          {/* <div style={{ margin: '25px 0 0' }}>
            <p style={{ fontSize: 12, color: '#394654', lineHeight: 1.25 }}>
              * &nbsp;Voucher hanya bisa di-redeem 1 jam sebelum kelas dimulai.
              Pastikan kamu
            </p>
            <p
              style={{
                fontSize: 12,
                color: '#394654',
                lineHeight: 1.25,
                marginLeft: '14px',
              }}
            >
              mengetahui jadwal kelas yang kamu pilih.
            </p>
            <p
              style={{
                margin: '5px 0 0',
                fontSize: 12,
                color: '#394654',
                lineHeight: 1.25,
              }}
            >
              * &nbsp;Khusus pembelian melalui Pijar Mahir, kamu cukup
              memasukkan kode vouchernya
            </p>
            <p
              style={{
                marginLeft: '14px',
                fontSize: 12,
                color: '#394654',
                lineHeight: 1.25,
              }}
            >
              dari digit keenam. Contoh:
              <span
                style={{
                  fontSize: 12,
                  fontWeight: 600,
                  textTransform: 'uppercase',
                }}
              >
                &nbsp;WDP115ff6ff7ff8ff --&gt; 5ff6ff7ff8ff
              </span>
            </p>
          </div>
          <div css={redeemSupportStyle}>
            <span>
              Mengalami kendala saat me-redeem voucher?
              <a
                href="https://www.kode.id/pages/contact-us"
                target="_blank"
                rel="noopener noreferrer"
              >
                Hubungi Kami
              </a>
            </span>
          </div> */}
        </div>
      </div>
    );
  }

  if (user && voucherRedemptionApiState === API_STATUS.SUCCESS) {
    return (
      <div css={rootContainerStyle}>
        <div css={contentContainerStyle} style={{ padding: 0, minHeight: '15vh' }}>
          <div css={redeemSuccessBannerStyle} />
          <div css={redeemSuccessContentContainerStyle}>
            <div css={redeemSuccessHeadingStyle}>
              Submission telah berhasil dikirim.
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <div>error</div>; // TODO: dedicated error page
};

export default SubmitPrakerjaSubmission;
