import { css } from '@emotion/core';

export const containerFileStyle = css`
  margin: 20px 0 0;
  flex-direction: column;
  padding: 50px 20px;
  border: 2px solid #ebeced;
  letter-spacing: 2.8px;
  border-radius: 10px;
  text-align: center;
  outline: none;
  width: 100%;
  font-size: 14px;
  line-height: 40px;
  color: #394654;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const fileInputStyle = css`
  padding-left: 179px;
  ::-webkit-file-upload-button {
    display: none;
  }
  @media (max-width: 940px) {
    padding-left: 120px;
  }
  @media (max-width: 518px) {
    padding-left: 50px;
  }

  &:focus {
    border-color: #394654;
  }
  &.second-input {
    margin: 20px 0 0;
  }
`;

export const buttonFileStyle = css`
  border-radius: 10px;
  padding: 10px 20px;
  background-color: transparent;
  cursor: pointer;
  border: 2px solid #ebeced;
`;

export const textFileNameStyle = css`
  font-weight: 700;
  font-size: 10px;
  line-height: 1.5;
  letter-spacing: 1;
`;

export const buttonCancel = css`
  color: red;
  cursor: pointer;
`;

export const selectInputStyle = css`
  border: none;
`;

export const selectInputContainer = css`
  cursor: pointer;
  padding: 20px 20px;
  border: 2px solid #ebeced;
  border-radius: 10px;
  text-align: center;
  outline: none;
  width: 100%;
  font-size: 14px;
  line-height: 40px;
  color: #394654;
  &:focus {
    border-color: #394654;
  }
  &.second-input {
    margin: 20px 0 0;
  }
`;
